/*==========================
Progressbar Area 
============================*/
.rn-progress-bar{

    .single-progress{
        position: relative;
        margin-top: 10px;
        .title{
            font-size: 15px;
            margin-bottom: 10px;


            @media #{$sm-layout} {
                font-size: 13px;
            }

        }
        .progress{
            height: 8px;
            border-radius: 4px;
            box-shadow: none;
            background-color: #f5f7fd;
            overflow: visible;

            @media #{$sm-layout} {
                height: 5px;
            }

            @media #{$small-mobile} {
                height: 5px;
            }

            @media #{$large-mobile} {
                height: 5px;
            }


            .progress-bar{

                background-color: #f81f01;
                background-image: linear-gradient(-224deg,#f81f01 0,#ee076e 100%);
                position: relative;
                color: #ee076e;
            }

        }

        .label{
            position: absolute;
            right: 0;
            top: 0;
            font-size: 15px;

            @media #{$sm-layout} {
                font-size: 12px;
                top: 20px;
            }
        }
        
        &.custom-color--1 {
            .progress{
                .progress-bar{
                    color: rgb(97, 253, 237);
                    background-color: rgb(13, 138, 188);
                    background-image: linear-gradient(-224deg, rgb(13, 138, 188) 0px, rgb(97, 253, 237) 100%);
                }
            }
        }
       
    }

}


    