.skillsBody {
  background: #0d1a26;
  position: relative;
  border-style: solid;
  border-color: #0d1a26;
}

.skillsContainer {
  margin: 5% 6% 8% 6%;
  padding: 30px 20px 30px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(245, 245, 245, 1);
  box-shadow: 0px 5px 5px 5px rgba(245, 245, 245, 0.2);
  width: 88%;
}

.skillsTitle {
  width: 100%;
  padding: 20px 0px 3% 5%;
  font-family: Raleway, sans-serif;

  span {
    color: rgba(41, 217, 185, 1);
    font-size: 2.5em;
    text-align: center;
    font-family: Raleway, sans-serif;
    font-weight: 900;
    letter-spacing: 2px;

    @media #{$sm-layout} {
    }

    @media #{$extra-device} {
    }
  }
}

.skillsContent {
  font-family: Raleway, sans-serif;
  margin: 0px 0px 0px 0px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 1);
  text-align: center;

  @media #{$sm-layout} {
    padding-top: 40px;
  }
}
.skillsContentLeft {
  font-family: Raleway, sans-serif;
  margin: 0px 0px 0px 0px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  border-right: solid;
  border-color: #003b59;

  @media #{$sm-layout} {
    padding-top: 40px;
    border-style: none;
  }
}
.skillsContentRight {
  font-family: Raleway, sans-serif;
  margin: 0px 0px 0px 0px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  border-left: solid;
  border-color: #003b59;

  @media #{$sm-layout} {
    padding-top: 40px;
    border-style: none;
  }
}
.skillsDetails {
  font-family: Raleway, sans-serif;

  h2 {
    font-size: 1.6em;
    color: rgba(41, 217, 185, 1);
    font-weight: 600;
    padding-bottom: 20px;
    text-decoration: underline;
  }
  p {
  }
}

.skillsDetailsCenter {
  font-family: Raleway, sans-serif;

  h2 {
    font-size: 1.6em;
    color: rgba(41, 217, 185, 0.9);
    font-weight: 600;
    padding-bottom: 20px;
    text-decoration: underline;
  }
  p {
  }
}

.skillList {
  font-size: 1.2em;
  list-style-type: none;
  line-height: 25px;
}

.skillList:hover {
  color: rgba(41, 217, 185, 0.9);
  cursor: default;
}

.valueList {
  font-size: 0.9em;
  list-style-type: none;
}

.skillsScroll {
  text-align: center;
  padding-top: 14px;
  width: 70%;
  float: right;
  border-radius: 6px;
  background-image: linear-gradient(
    to right,
    rgba(41, 217, 185, 1),
    rgba(41, 217, 185, 0.3)
  );
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.2),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-top: 18vh;
}

.skillsScroll:hover {
  background-image: linear-gradient(
    to left,
    rgba(41, 217, 185, 1),
    rgba(41, 217, 185, 0.3)
  );
  transform: translateX(1px);
}

.skillsDownloadResume {
  text-align: center;
  width: 70%;
  float: left;
  border-radius: 6px;
  padding: 14px;
  background-image: linear-gradient(
    to right,
    rgba(41, 217, 185, 1),
    rgba(41, 217, 185, 0.3)
  );

  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.2),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-top: 11vh;

  a {
    text-decoration: none;
    color: black;
    font-size: 1.2em;
    font-weight: 600;
  }

  @media #{$sm-layout} {
    margin-top: 10vh;
  }
}

.skillsDownloadResume:hover {
  background-image: linear-gradient(
    to left,
    rgba(41, 217, 185, 1),
    rgba(41, 217, 185, 0.3)
  );
  transform: translateX(1px);
}
