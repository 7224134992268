.welcomeContainer {
  background-color: #0d1a26;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 50px;
  height: 80vh;
  @media #{$sm-layout} {
    height: 70vh;
    margin-top: 70px;
  }
}

.welcome {
  color: #ffffff;
  font-family: Raleway, sans-serif;
  font-size: 1.5em;
  text-align: left;
  position: absolute;
  z-index: 5;
  padding-top: 15px;
  padding-left: 15px;
  margin-top: 20%;
  width: 70%;
  left: 20%;
  right: 10%;

  @media #{$sm-layout} {
    margin-top: 20%;
    font-size: 0.9em;
    text-align: left;
    padding-left: 0px;
    width: 70%;
    left: 10%;
    right: 20%;
  }

  @media #{$extra-device} {
    left: 5%;
  }

  strong {
    color: #33ffff;
  }
}

.welcomeMessage {
  background-color: rgba(6, 11, 38, 0.3);
  padding-top: 15px;
  padding-left: 15px;
  border-radius: 10px;
  border-style: solid;
  @media #{$sm-layout} {
    background-color: rgba(13, 26, 38, 0.7);
  }
}

.welcomeNavContainer {
  display: flex;
  width: 100%;
}
.welcomeNav {
  background-color: rgba(6, 11, 38, 0.3);
  font-size: 0.9em;
  text-align: center;
  margin: 15px 15px 0px 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  border-radius: 10px;
  border-style: solid;

  @media #{$sm-layout} {
    background-color: rgba(6, 11, 38, 0.5);
  }
}

.welcomeNav:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: scale(1.02);
}

.welcomeNav a:hover {
  text-decoration: none;
}

.welcomeBackground {
  background-color: #0d1a26;
  @media #{$sm-layout} {
    width: 225vw;
  }
}

.welcomeButton {
  background-color: #0d1a26;
}
