.portfolioBody {
  background: #0d1a26;
  position: relative;
  border-style: solid;
  border-color: #0d1a26;
}

.portfolioContainer {
  margin: 5% 6% 8% 6%;
  padding: 30px 0px 30px 0px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(245, 245, 245, 1);
  box-shadow: 0px 5px 5px 5px rgba(245, 245, 245, 0.2);
  width: 88%;
}

.portfolioTitle {
  width: 100%;
  padding: 20px 0px 3% 5%;
  font-family: sans-serif;

  span {
    color: rgba(41, 217, 185, 1);
    font-size: 2.5em;
    text-align: center;
    font-family: Raleway, sans-serif;
    font-weight: 900;
    letter-spacing: 2px;

    @media #{$sm-layout} {
    }

    @media #{$extra-device} {
    }
  }
}

.RowProject1 {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
}

.projectsquare {
  border-radius: 5px;
  border-style: solid;
  border-color: rgba(41, 217, 185, 1);
  background-color: rgba(41, 217, 185, 0.7);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  @media #{$sm-layout} {
    margin-bottom: 10%;
  }
}

.projectPhoto {
  height: 250px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  opacity: 1;
  object-fit: fill;
  transition: 0.5s ease;
  backface-visibility: hidden;
  display: block;
  background-color: rgba(6, 11, 38, 0.6);
}

.projectPhotoImg {
  height: 250px;
  border-radius: 5px;
}
.projectPhoto:hover {
  opacity: 0.7;
}
.projectPhoto:hover .middle {
  opacity: 1;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;

  &:hover {
    transform: translate(-50%, -60%);
    -ms-transform: translate(-50%, -50%);
  }
}

.text {
  background: rgba(41, 217, 185, 0.9);
  color: black;
  font-size: 1em;
  padding: 16px 32px;
  font-family: Raleway, sans-serif;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.RowProject2 {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
}
.projectContent {
  font-size: 1.2em;
}

//The content within the collaspable area
.Collapsible__contentInner {
  color: rgba(0, 0, 0, 1);
  font-family: Raleway, sans-serif;
  font-size: 12px;
  display: block;
  margin: 10px;
  padding: 5px;
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  @extend %fa-icon;
  @extend .fas;
  display: block;
  font-size: 0.8em;
  letter-spacing: 1px;
  color: black;
  position: relative;
  padding: 4%;
  margin: 4px 4px;
  background-color: rgba(41, 217, 185, 0.9);
  border-radius: 5px;
  &:hover {
    background-color: rgba(41, 217, 185, 0.1);
    transform: scale(1.01);
    border: #e0e0e0 solid 1px;
    border-radius: 5px;
  }
  &:after {
    content: fa-content($fa-var-arrow-alt-circle-down);
    position: absolute;
    right: 10px;
    top: 10px;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.projectAreaBody {
  background: #0d1a26;
  position: relative;
  border-style: solid;
  border-color: #0d1a26;
}

.projectAreaContainer {
  margin: 5% 6% 8% 6%;
  padding: 30px 0px 30px 0px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(245, 245, 245, 1);
  box-shadow: 0px 5px 5px 5px rgba(245, 245, 245, 0.2);
  width: 88%;
}

.projectAreaTitle {
  width: 84%;
  padding: 20px 8% 15px 8%;
  font-family: Raleway, sans-serif;

  span {
    color: rgba(41, 217, 185, 1);
    font-size: 2.5em;
    text-align: center;
    font-family: Raleway, sans-serif;
    font-weight: 900;
    letter-spacing: 2px;

    @media #{$sm-layout} {
    }

    @media #{$extra-device} {
    }
  }
}

.projectVideoContainer {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
}
.projectVideo {
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4), 0 1px 10px 0 rgba(0, 0, 0, 0.2),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }
}

.videoTag {
  max-width: 86%;
  margin-left: 8%;
  margin-right: 8%;

  border-radius: 6px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4), 0 1px 10px 0 rgba(0, 0, 0, 0.2),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.videoTag2 {
  max-width: 100%;
  max-height: 92%;
  border-radius: 6px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4), 0 1px 10px 0 rgba(0, 0, 0, 0.2),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.projectOverview {
  font-family: Raleway, sans-serif;
  font-size: 1em;
  width: 84%;
  margin: 3% 6% 3% 10%;

  h2 {
    color: rgba(41, 217, 185, 1);
    font-weight: 900;
    font-size: 1.5em;
    letter-spacing: 2px;
  }

  p {
    font-size: 0.9em;
    padding-left: 3px;
  }
}

ul {
  list-style-type: none;
}
