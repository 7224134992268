.footerBody {
  background-color: rgba(13, 26, 38, 0.9);
  position: relative;
  border-style: solid;
  border-color: #0d1a26;
}

.footerConnect {
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
  font-family: Raleway, sans-serif;

  h2 {
    text-decoration: underline;
    font-size: 1.8em;
  }
}

.footerConnect2 {
  text-align: center;
  display: inline-block;
  justify-content: space-around;
  color: #ffffff;
  font-family: Raleway, sans-serif;
  margin-top: 1%;
  padding: 10px;
  p {
    margin-top: 4%;
  }
}

.footerLinks {
  a {
    font-size: 2.5em;
    color: #ffffff;
    padding: 10px;
    &:hover {
      color: rgba(41, 217, 185, 1);
    }
  }
}

.btn-solidFooter {
  border: 2px solid rgba(41, 217, 185, 1);
  background: rgba(6, 11, 38, 0.3);
  padding: 0px 10px 10px 10px;
  font-size: 1.2em;
  font-weight: 600;
  color: #ffffff;
  border-radius: 10px;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  span {
    font-size: 1.8em;
  }

  a {
    text-decoration: none;
    color: #ffffff;
  }

  &:hover {
    color: rgba(41, 217, 185, 1);
    background: rgba(255, 255, 255, 0.2);
    transform: scale(0.95);
  }
}
