/*=============================
	Screen Size Layouts
==============================*/

$smlg-device: "only screen and (max-width: 1199px)";

$extra-device: "only screen and (min-width: 1600px) and (max-width: 1919px)";

$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm-layout: "only screen and (max-width: 767px)";
$large-mobile: "only screen and (max-width: 575px)";
/* container 450px*/
$small-mobile: "only screen and (max-width: 479px)";
/* container 300px*/

/*=============================
    Back To Top
==============================*/
.backToTop {
  > div {
    z-index: 999;
    width: 50px;
    height: 50px;
    line-height: 49px;
    border-radius: 25%;
    background-color: rgba(41, 217, 185, 0.9);
    text-align: center;
    overflow: hidden;
    z-index: 999 !important;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.3),
      0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }

  > div:hover {
    transform: scale(1.04);
    transform: translateY(-5px);
  }
}

/*=============================
	Overlay styles
==============================*/

[data-overlay],
[data-black-overlay] {
  position: relative;
  z-index: 1;
}

[data-overlay] > div,
[data-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > * {
  position: relative;
  z-index: 1;
}

[data-overlay]:before,
[data-black-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

[data-black-overlay]:before {
  background-color: rgba(6, 11, 38, 1);
}

@for $i from 1 through 10 {
  [data-overlay="#{$i}"]:before,
  [data-black-overlay="#{$i}"]:before {
    opacity: #{$i * 0.1};
  }
}
