/*


Import individual Styles Into Here



*/
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

/**************************************
    Default Styles
***************************************/
@import "default/shortcode";

/**************************************
    Header Styles
***************************************/
@import "common/header";

/**************************************
    TitlePage Styles
***************************************/
@import "common/titlepage";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@import "common/progress";
@import "common/contact";
@import "common/portfolio";
@import "common/about";
@import "common/skills";
@import "common/footer";
