.aboutBody {
  background: #0d1a26;
  position: relative;
  border-style: solid;
  border-color: #0d1a26;
}

.aboutContainer {
  margin: 5% 6% 8% 6%;
  padding: 30px 15px 40px 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(245, 245, 245, 1);
  box-shadow: 0px 5px 5px 5px rgba(245, 245, 245, 0.2);
  width: 88%;
}

.sectionTitle {
  text-align: left;
  width: 100%;
  padding: 20px 0px 10px 20px;
  font-family: Raleway, sans-serif;

  span {
    color: rgba(41, 217, 185, 1);
    font-size: 2.5em;
    text-align: center;
    font-family: Raleway, sans-serif;
    font-weight: 900;
    letter-spacing: 2px;

    @media #{$sm-layout} {
    }

    @media #{$extra-device} {
    }
  }
}

.aboutPic {
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4), 0 1px 10px 0 rgba(0, 0, 0, 0.2),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }
}

.aboutContent {
  font-family: Raleway, sans-serif;
  margin: 0px 0px 0px 0px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 1);

  @media #{$sm-layout} {
    padding-top: 40px;
  }
}

.aboutDetails {
  font-family: Raleway, sans-serif;

  h2 {
    font-size: 1.6em;
    color: rgba(41, 217, 185, 1);
    font-weight: 600;
    padding-bottom: 10px;
  }
  p {
    line-height: 30px;
  }
}

.aboutScroll {
  text-align: center;
  padding-top: 14px;
  width: 50%;
  float: right;
  border-radius: 6px;
  background-image: linear-gradient(
    to right,
    rgba(41, 217, 185, 1),
    rgba(41, 217, 185, 0.3)
  );
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.2),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.aboutScroll:hover {
  background-image: linear-gradient(
    to left,
    rgba(41, 217, 185, 1),
    rgba(41, 217, 185, 0.3)
  );
  transform: translateX(4px);
}
