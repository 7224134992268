.contactBody {
  background: #0d1a26;
  position: relative;
  border-style: solid;
  border-color: #0d1a26;
}

.contactContainer {
  margin: 5% 6% 8% 6%;
  padding: 30px 0px 30px 0px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(245, 245, 245, 1);
  box-shadow: 0px 5px 5px 5px rgba(245, 245, 245, 0.2);
  width: 88%;
}

.contactTitle {
  text-align: left;
  width: 100%;
  padding: 20px 0px 1% 5%;
  font-family: Raleway, sans-serif;

  span {
    color: rgba(41, 217, 185, 1);
    font-size: 2.5em;
    text-align: center;
    font-family: Raleway, sans-serif;
    font-weight: 900;
    letter-spacing: 2px;

    @media #{$sm-layout} {
    }

    @media #{$extra-device} {
    }
  }
}

.contact {
  width: 90%;
  margin: 5% 5% 2% 5%;
}

.rn-address {
  margin-top: 30px;
  border: 1px solid #ebebeb;
  padding: 40px;
  border-radius: 10px;
  padding-top: 54px;
  background: #fff;
  padding-bottom: 50px;
  .icon {
    border: 1px solid #ebebeb;
    width: 78px;
    height: 78px;
    color: rgba(41, 217, 185, 1);
    line-height: 73px;
    text-align: center;
    border-radius: 100%;
    background: #ffffff;
    font-size: 28px;
    position: absolute;
    top: -13px;
    left: 47px;
  }
  .inner {
    h4 {
      &.title {
        font-size: 18px;
        font-weight: 700;
      }
    }
    p {
      font-size: 18px;
      margin-bottom: 0;
      color: rgba(29, 29, 36, 0.75);
      @media #{$lg-layout} {
        font-size: 16px;
      }
      @media #{$md-layout} {
        font-size: 16px;
      }
      @media #{$sm-layout} {
        font-size: 16px;
      }
      br {
        @media #{$lg-layout} {
          display: none;
        }

        @media #{$md-layout} {
          display: none;
        }

        @media #{$sm-layout} {
          display: none;
        }
      }
      a {
        color: rgba(29, 29, 36, 0.75);
        &:hover {
          color: rgba(41, 217, 185, 1);
        }
      }
    }
  }
  &:hover {
    .icon {
      background: rgba(41, 217, 185, 1);
      border-color: rgba(41, 217, 185, 1);
      color: #fff;
    }
  }
}

.contact-form--1 {
  margin-top: 5%;
  margin-bottom: 3%;
  font-family: Raleway, sans-serif;
  label {
    display: block;
    margin-bottom: 0;
  }

  input,
  textarea {
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: all 0.3s ease;
    height: 50px;
    line-height: 46px;
    margin-bottom: 20px;
    outline: none;
    color: #1f1f25;
    font-size: 15px;
    letter-spacing: 0.1px;
    &:focus {
      border-color: rgba(41, 217, 185, 1);
    }
  }
  textarea {
    height: 120px;
  }
}

.btn-solid {
  border: 2px solid rgba(41, 217, 185, 1);
  background: rgba(41, 217, 185, 1);
  padding: 10px;
  font-size: 1.2em;
  font-weight: 600;
  color: #ffffff;
  width: 20%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  &:hover {
    color: rgba(41, 217, 185, 1);
    background: #ffffff;
    transform: scale(0.95);
  }
}
