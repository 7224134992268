.navbar {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex;
  font-family: Raleway, sans-serif;
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: rgba(13, 26, 38, 0.9);
  @media #{$sm-layout} {
    background-color: rgba(13, 26, 38, 1);
  }
}

.resumeButton {
  color: #ffffff;
  width: 40%;

  @media #{$sm-layout} {
    display: none;
  }
}

.menu-bars {
  margin-left: 1rem;
  font-size: 2rem;
  background: none;
  color: #0d1a26;
}

.menu-socialBars {
  margin-left: 2rem;
  font-size: 2rem;
  color: #0d1a26;
}

.menu-socialBars:hover {
  transform: scale(1.25);
}
.nav-menu {
  z-index: 2;
  width: 200px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  overflow: scroll;
  border-radius: 10px;
  font-family: Raleway, sans-serif;

  @media #{$extra-device} {
    height: 80vh;
  }
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  background-size: cover;
  background-color: rgba(13, 26, 38, 1);
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 85%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #3491ff;
  transform: translateX(5px);
}

.socialBar {
  width: 200px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  border-radius: 4px;
  margin-top: 10vh;

  @media #{$sm-layout} {
    margin-top: 5%;
  }
}

.socialBar a {
  height: 40px;
  width: 40px;
}

.nav-menu::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.navbar-toggle a:hover {
  transform: scale(0.9);
}

span {
  margin-left: 16px;
}

.navbar-on:hover {
  transform: scale(1.1);
}

.navbarPhoto {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.btn-solidHeader {
  border: 2px solid #33ffff;
  background: rgba(6, 11, 38, 0.3);
  padding: 0px 5px 5px 5px;
  font-size: 1em;
  font-weight: 600;
  color: #ffffff;
  border-radius: 10px;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  span {
    font-size: 1.5em;
  }

  a {
    text-decoration: none;
    color: #ffffff;
  }

  &:hover {
    color: rgba(41, 217, 185, 1);
    background: rgba(255, 255, 255, 0.2);
    transform: scale(0.98);
  }
}
